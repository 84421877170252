@import '../../assets/style/variable.scss';

.home-page {
  @keyframes move {
    0% {
      top: -0.5rem;
    }
    100% {
      top: 0.5rem;
    }
  }

  .notice-box {
    height: 3.8rem;
    .notice-icon {
      width: 1.5rem;
    }
  }
  
  .shine-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-shadow: 0.1rem 0.1rem 0.4rem #895630;
  }
  
  .common-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4rem;
    border-radius: 2.4rem;
    background-color: $bg;
    font-size: 1.2rem;
    padding: 0 3.5rem;
    text-shadow: 0.15rem 0.15rem 0.2rem #000000;
    transition: all linear 0.3s;
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
      box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0.1);
    }
    &:active {
      filter: brightness(0.9);
      box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0.1);
    }

    &.href-a {
      padding: 0;
      a {
        height: 100%;
        padding: 0 3.5rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  
  .r-icon {
    position: relative;
    width: 3.6rem;
    animation: move 3s linear 0s infinite alternate;
  }
  
  .b-icon {
    width: 1.6rem;
  }
}