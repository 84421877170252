@import '../../assets/style/variable.scss';

.go-page {
  .icon {
    width: 2rem;
  }

  .input-wrapper {
    border: 1px solid $light;
    border-radius: 0.4rem;
    padding: 0 0.5rem;
    &:hover {
      border: 1px solid $warning;
    }
  }

  .input-wrapper2 {
    border: 1px solid $light;
    border-radius: 0.4rem;
    padding: 0.2rem 0.5rem;
    background-color: #3A3A3A;
    input {
      border: 1px solid $light;
      background-color: $black;
      &:hover {
        border: 1px solid $warning;
      }
      &[readonly] {
        border: 1px solid $light;
      }
    }

    &.bg2 {
      background-color: #14182B;
    }
  }

  .increase-box {
    .icon {
      width: 1.5rem;
    }
    .tag {
      color: $black;
      padding: 0.1rem 0.2rem;
      border-radius: 0.2rem;
      &.success {
        background: $success2;
      }
      &.danger {
        background: $danger2;
      }
    }
  }

  // 数字输入框
  .ant-input-number {
    border: 1px solid $light;
    &:hover {
      border-color: $warning !important;
    }
    .ant-input-number-input {
      height: 2rem;
      text-align: right;
      color: $white;
      border: none !important;
    }
    .ant-input-number-handler {
      display: none !important;
    }
  }


  .star {
    width: 1.4rem;
  }

  .carousel-slider {
    padding: 0 3rem;
  }
}